import React from "react"
import { Link } from "@flogy/gatsby-theme-fgs-layout"

const customFeatures = (companyAccusative, companyDative) => [
  {
    key: "liebesgeschichte",
    title: "Liebesgeschichte",
    body: (
      <p>
        Nach der Installation der Klar + Einfach Mobile App werden regelmässig
        Push Benachrichtigungen an das verwendete Gerät gesendet, sobald für das
        Gerät neue Teile der Liebesgeschichte verfügbar sind. Dazu wird zur
        eindeutigen Erkennung des Geräts dessen UUID an an Server von Amazon AWS
        übermittelt und dort bearbeitet (
        <Link href="https://aws.amazon.com/de/privacy/">
          Datenschutzerklärung von Amazon AWS
        </Link>
        ). Ausser der UUID werden keine Daten permanent gespeichert, können aber
        dennoch in Log-Dateien enthalten sein.
      </p>
    ),
  },
]

export default customFeatures
